.myImage {
  width: 30vw;
  height: 30vw;
}
.rotating {
  width: 45vw;
}
@media (prefers-reduced-motion: no-preference) {
  .rotating {
    animation: rotating infinite 10s linear;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
